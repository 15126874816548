<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <div class="input_search">
          <input
            type="text"
            placeholder="원자재명 또는 규격 검색"
            :value="searchStockText"
            @input.prevent="setSearchText($event)"
          />
          <button>
            <i class="fa fa-search"></i>
          </button>
        </div>

        <div class="input_text">
          <label>창고</label>
          <my-local-selectric
            :id="'material_store_selectric'"
            searchStoreLocation
            :watch="searchStoreLocation"
            :options="store_options_all"
            @changeValue="searchStore($event)"
          >
          </my-local-selectric>
        </div>
        <div class="input_text">
          <label>구분</label>
          <my-local-selectric
            :id="'material_resource_selectric'"
            :watch="searchMaterialResource"
            :options="material_resource_options"
            @changeValue="searchResource($event)"
          >
          </my-local-selectric>
        </div>
        <div class="input_checkbox">
          <label for="checkbox1" class="label_txt">재고량 0 제외</label>
          <input type="checkbox" id="checkbox1" @click="exceptStockZero" />
          <label
            for="checkbox1"
            class="chk_box"
            :class="{ active: chkStockZero }"
          >
            <i class="fa fa-check"></i>
          </label>
        </div>
        <button class="excel_btn" @click="exportExcel">엑셀로 출력</button>
      </div>
      <div class="search_length">
        <h6>조회수 : {{ filterMaterialTotalStock.length }}건</h6>
        <h6>
          {{
            selectedIndex != null && selectedIndex != -1
              ? `[${
                  findInfoFromId(
                    material,
                    filterMaterialTotalStock[selectedIndex].material_id,
                  ).name
                }` +
                `${
                  findInfoFromId(
                    material,
                    filterMaterialTotalStock[selectedIndex].material_id,
                  ).standard != ''
                    ? ' - ' +
                      findInfoFromId(
                        material,
                        filterMaterialTotalStock[selectedIndex].material_id,
                      ).standard
                    : ``
                }] `
              : ``
          }}조회수 : {{ filteredMaterialControl.length }}건
        </h6>
      </div>
      <div>
        <div class="mes_tbl_wrap total_list">
          <table class="mes_tbl">
            <colgroup>
              <col v-for="(n, index) in 4" :key="index" />
            </colgroup>
            <thead>
              <tr>
                <th>원자재명</th>
                <th>규격</th>
                <th>재고량(단위)</th>
                <th>그래프</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(stockStatus, index) in filterMaterialTotalStock"
                :key="stockStatus.id"
                @click="selectRow(index)"
                :class="{ active: selectedIndex == index }"
              >
                <td class="text_left">
                  {{ findInfoFromId(material, stockStatus.material_id).name }}
                </td>
                <td class="text_left">
                  {{
                    findInfoFromId(material, stockStatus.material_id).standard
                  }}
                </td>
                <td class="text_right">
                  <b>
                    {{
                      $makeComma(
                        findInfoFromId(
                          materialTotalStock,
                          stockStatus.material_id,
                        ).stock_quantity,
                      )
                    }}
                  </b>
                  <!-- {{ `(${getUnitName(index)})` }} -->
                  {{
                    `(${
                      unitCodes.length > 0 && material.length > 0
                        ? findInfoFromId(
                            unitCodes,
                            findInfoFromId(material, stockStatus.material_id)
                              .incoming_unit_id,
                          ).name
                        : ''
                    })`
                  }}
                </td>
                <td>
                  <button class="btn_tbl" @click="openModal(index)">
                    그래프
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="mes_tbl_wrap control_list">
          <table class="mes_tbl" :class="{ manage: managementMode }">
            <colgroup>
              <col v-for="(n, index) in managementMode ? 8 : 7" :key="index" />
            </colgroup>
            <thead>
              <tr>
                <th>입고일</th>
                <th>거래처명</th>
                <!-- <th>원자재명</th> -->
                <th>규격</th>
                <th>입고식별표</th>
                <th>창고명</th>
                <th>세부위치</th>
                <th>재고량(단위)</th>
                <th v-if="managementMode">재고수정</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(selectStock, index) in filteredMaterialControl"
                :key="selectStock.id"
              >
                <td>
                  {{
                    selectStock.create_time.substr(0, 19) | formatDateNoHours
                  }}
                </td>
                <td class="text_left">
                  {{
                    stockStatusList.find(
                      x =>
                        Number(x.barcode_num.substr(9, 5)) ==
                        selectStock.purchase_material_id,
                    ).company_name
                  }}
                </td>
                <!-- <td class="text_left">
                  {{ findInfoFromId(material, selectStock.material_id).name }}
                </td> -->
                <td class="text_left">
                  {{
                    findInfoFromId(material, selectStock.material_id).standard
                  }}
                </td>
                <td>
                  {{ selectStock.barcode_num }}
                </td>
                <td v-if="!selectStock.is_modify">
                  {{ findInfoFromId(stores, selectStock.store_id).name }}
                </td>
                <td class="input_manage" v-else>
                  <div class="select_wrap" v-show="managementMode">
                    <my-local-selectric
                      :id="`store_selectric_${index}`"
                      :watch="selectStock.store_id"
                      :options="store_options"
                      @changeValue="
                        $event => {
                          selectStock.location_id = null;
                          selectStock.store_id = $event;
                        }
                      "
                    ></my-local-selectric>
                  </div>
                </td>
                <td v-if="!selectStock.is_modify">
                  {{
                    selectStock.location_id == null
                      ? ''
                      : getStoreLocation(selectStock).find(
                          x => x.value == selectStock.location_id,
                        ).label
                  }}
                </td>
                <td class="input_manage" v-else>
                  <div class="select_wrap" v-show="managementMode">
                    <my-local-selectric
                      :id="`store_location_selectric_${index}`"
                      :watch="selectStock.location_id"
                      :options="getStoreLocation(selectStock)"
                      @changeValue="
                        $event => (selectStock.location_id = $event)
                      "
                    ></my-local-selectric>
                  </div>
                </td>
                <td class="text_right" v-if="!selectStock.is_modify">
                  <b>{{ `${$makeComma(selectStock.quantity)}` }}</b>
                  {{ `(${getFilteredControlUnitName(index)})` }}
                </td>
                <td class="input_manage" v-else>
                  <input
                    type="text"
                    inputmode="decimal"
                    :value="$makeComma(selectStock.quantity_temp)"
                    @input="
                      $inputNumber(
                        $event,
                        selectStock,
                        'quantity_temp',
                        99999999,
                        0,
                      )
                    "
                  />
                </td>
                <td v-if="managementMode">
                  <button class="btn_tbl" @click="changeStock(selectStock)">
                    {{ selectStock.is_modify ? '완료' : '수정' }}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <graph-modal
      v-if="isGraphModalOpen"
      :filteredStockStatus="filteredStockStatus"
      :materialTotalStock="filterMaterialTotalStock"
      :key="key"
      @redraw="key += 1"
      @onclose="isGraphModalOpen = false"
      @changedate="changeModalDate($event)"
      :startDate="modal_start_date"
      :endDate="modal_end_date"
    ></graph-modal>
    <div class="modal_layer" v-if="isGraphModalOpen" @click="closeModal"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import XLSX from 'xlsx';
import fetchMixin from '@/mixins/fetch';
import modalMixin from '@/mixins/modal';
import GraphModal from '@/layouts/components/GraphModal';
import MyLocalSelectric from '@/layouts/components/MyLocalSelectric';
import { yyyymmdd, yyyy년MM월dd일HH시mm분ss초 } from '@/utils/func';

export default {
  mixins: [fetchMixin, modalMixin],
  components: {
    GraphModal,
    MyLocalSelectric,
  },
  data() {
    return {
      isGraphModalOpen: false,
      key: 0,
      modal_start_date: null,
      modal_end_date: null,
      standardText: '',
    };
  },
  computed: {
    ...mapGetters({
      managementMode: 'getManagementModeFromMaterialStatusPage',
      searchStockText: 'getSearchStockTextFromMaterialStockStatus',
      stockStatusList: 'getMaterialStockStatusListFromMaterialStockStatus',
      materialTotalStock: 'getMaterialTotalStock',
      materialInOutType: 'getMaterialInOutType',
      materialControlList: 'getMaterialControl',
      material: 'getMaterial',
      unitCodes: 'getUnitCodes',
      stores: 'getStores',
      selectedIndex: 'getSelectedIndexFromStock',
      store_options_all: 'getStoresForSelectricAll',
      store_options: 'getStoresForSelectric',
      material_resource_options: 'getMaterialTypeForSelectricAll',
      searchStoreLocation: 'getSearchStoreLocationFromMaterialStockStatus',
      searchMaterialResource:
        'getSearchMaterialResourceFromMaterialStockStatus',
      chkStockZero: 'getCheckExceptStorckZeroFromMaterialStockStatus',
    }),
    filterMaterialTotalStock() {
      if (this.materialTotalStock !== undefined) {
        const Hangul = require('hangul-js');

        return this.materialTotalStock
          .filter(
            x =>
              this.findInfoFromId(this.material, x.material_id).name.includes(
                this.searchStockText,
              ) ||
              Hangul.d(
                this.findInfoFromId(this.material, x.material_id).name,
                true,
              )
                .map(x => x[0])
                .join('')
                .includes(this.searchStockText) ||
              this.findInfoFromId(
                this.material,
                x.material_id,
              ).standard.includes(this.searchStockText) ||
              Hangul.d(
                this.findInfoFromId(this.material, x.material_id).standard,
                true,
              )
                .map(x => x[0])
                .join('')
                .includes(this.searchStockText),
          )

          .filter(
            x =>
              this.materialControlList.filter(
                y =>
                  y.material_id == x.material_id &&
                  (y.store_id == this.searchStoreLocation ||
                    this.searchStoreLocation == null),
              ).length > 0,
          )
          .filter(
            x =>
              this.findInfoFromId(this.material, x.material_id)
                .resource_type_id == this.searchMaterialResource ||
              this.searchMaterialResource == null,
          )
          .filter(x =>
            this.chkStockZero ? x.stock_quantity > 0 : x.stock_quantity >= 0,
          );
      } else {
        return [];
      }
    },
    filteredStockStatus() {
      if (this.stockStatusList !== undefined && this.selectedIndex != -1) {
        const filterList = this.stockStatusList
          .filter(
            x =>
              x.material_id ==
                this.filterMaterialTotalStock[this.selectedIndex].material_id &&
              x.input_date >= this.modal_start_date,
          )
          .sort((a, b) => new Date(b.input_date) - new Date(a.input_date));

        let list = [];
        filterList.forEach(x => {
          const item = list.find(y => y.date == x.input_date);

          if (item == undefined) {
            if (
              x.material_in_out_type_id ==
              this.materialInOutType.find(x => x.detail == 'buy in').id
            ) {
              list.push({
                date: x.input_date,
                date_label: x.input_date,
                quantity: x.quantity,
                company_label: [
                  `[${x.input_date} 입고] ${x.company_name} ${this.$makeComma(
                    x.quantity,
                  )}`,
                ],
              });
            } else if (
              this.materialInOutType
                .filter(x =>
                  [
                    'using out',
                    'return out',
                    'discard out',
                    'package out',
                  ].includes(x.detail),
                )
                .map(x => x.id)
                .includes(x.material_in_out_type_id)
            ) {
              const outType = this.materialInOutType.find(
                y => y.id == x.material_in_out_type_id,
              ).detail;
              const in_out_type_arr = [
                { detail: 'using out', name: '출고' },
                { detail: 'return out', name: '반품출고' },
                { detail: 'discard out', name: '폐기' },
                { detail: 'package out', name: '포장출고' },
              ];
              list.push({
                date: x.input_date,
                date_label: x.input_date,
                quantity: -x.quantity,
                company_label: [
                  `[${x.input_date} ${
                    in_out_type_arr.find(t => t.detail == outType).name
                  }] ${this.$makeComma(x.quantity)}`,
                ],
              });
            }
          } else {
            if (
              x.material_in_out_type_id ==
              this.materialInOutType.find(x => x.detail == 'buy in').id
            ) {
              item.quantity = this.$decimalAdd(item.quantity, x.quantity);
              item.company_label.push(
                `[${x.input_date} 입고] ${x.company_name} ${this.$makeComma(
                  x.quantity,
                )}`,
              );
            } else if (
              this.materialInOutType
                .filter(x =>
                  [
                    'using out',
                    'return out',
                    'discard out',
                    'package out',
                  ].includes(x.detail),
                )
                .map(x => x.id)
                .includes(x.material_in_out_type_id)
            ) {
              const outType = this.materialInOutType.find(
                y => y.id == x.material_in_out_type_id,
              ).detail;
              const in_out_type_arr = [
                { detail: 'using out', name: '출고' },
                { detail: 'return out', name: '반품출고' },
                { detail: 'discard out', name: '폐기' },
                { detail: 'package out', name: '포장출고' },
              ];
              item.quantity = this.$decimalSub(item.quantity, x.quantity);
              item.company_label.push(
                `[${x.input_date} ${
                  in_out_type_arr.find(t => t.detail == outType).name
                }] ${this.$makeComma(x.quantity)}`,
              );
            } else {
              console.log('x값', x.material_in_out_type_id);
            }
          }
        });

        list.push({
          date: '1700-01-01',
          date_label: '전 재고',
          currentStock: 0,
          quantity: 0,
        });
        let total = this.lodash.clonedeep(
          this.$makeNumber(
            this.filterMaterialTotalStock[this.selectedIndex].stock_quantity,
          ),
        );
        list.forEach((x, index) => {
          if (index != list.length - 1) {
            if (index != 0) {
              total = this.$decimalSub(total, this.$makeNumber(x.quantity));

              list[index + 1].currentStock = this.lodash.clonedeep(total);
            } else {
              x.currentStock = this.$makeNumber(total);
              total = this.$decimalSub(total, this.$makeNumber(x.quantity));
              list[index + 1].currentStock = this.lodash.clonedeep(total);
            }
          }
        });

        return list
          .sort(
            (a, b) =>
              Number(a.date.replace(/-/gi, '')) -
              Number(b.date.replace(/-/gi, '')),
          )
          .filter(x => x.date <= this.modal_end_date);
      } else {
        return [];
      }
    },
    filteredMaterialControl() {
      if (this.materialControlList !== undefined && this.selectedIndex != -1) {
        return this.materialControlList
          .filter(
            x =>
              x.material_id ==
                this.filterMaterialTotalStock[this.selectedIndex].material_id &&
              this.$makeNumber(x.quantity) > 0,
          )
          .filter(
            x =>
              x.store_id == this.searchStoreLocation ||
              this.searchStoreLocation == null,
          )
          .sort(
            (a, b) =>
              new Date(b.create_time) - new Date(a.create_time) || b.id - a.id,
          );
        // .reverse();
      } else {
        return [];
      }
    },
  },
  methods: {
    getStoreLocation(stock) {
      if (stock.store_id == null) {
        return [{ label: '선택', value: null, detail: null }];
      } else {
        let options_arr = [{ label: '선택', value: null, detail: null }];
        const location = this.lodash.clonedeep(
          this.stores.find(x => x.id == stock.store_id).locations,
        );
        if (location.length > 0) {
          options_arr = options_arr.concat(
            location.map(x => {
              return {
                value: x.id,
                label: x.name,
                detail: x.detail,
              };
            }),
          );
        }
        return options_arr;
      }
    },
    async changeStock(item) {
      if (item.is_modify) {
        this.showSpinner();
        this.$store
          .dispatch('UPDATE_MATERIAL_CONTROL_STOCK', {
            id: item.id,
            quantity: this.$makeNumber(item.quantity_temp),
            store_id: item.store_id,
            location_id: item.location_id,
          })
          .then(() => {
            item.quantity = this.$makeNumber(item.quantity_temp);
            this.openOneButtonModal(
              '재고 수정 완료',
              '재고 수정이 완료되었습니다.',
            );
            this.FETCH('FETCH_MATERIAL_TOTAL_STOCK', '재고 현황');
          })
          .catch(error => {
            console.log(error);
            this.openOneButtonModal('재고 수정 실패', '재고 수정 중 오류발생');
          })
          .finally(() => {
            this.hideSpinner();
          });
      }
      item.is_modify = !item.is_modify;
    },
    changeModalDate(e) {
      this.modal_start_date = e.start_date;
      this.modal_end_date = e.end_date;
      this.key += 1;
    },
    setSearchText(e) {
      this.$store.commit(
        'setSearchStockTextToMaterialStockStatus',
        e.target.value,
      );
      this.$store.commit('setSelectedIndexToStock', -1);
    },
    selectRow(index) {
      this.$store.commit('setSelectedIndexToStock', index);
    },
    getUnitName(index) {
      const MaterialStatusStockTemp = this.materialTotalStock[index]
        .material_id;
      const findMId = this.findInfoFromId(
        this.material,
        MaterialStatusStockTemp,
      );
      if (findMId !== '') {
        const findUId = this.findInfoFromId(
          this.unitCodes,
          findMId.incoming_unit_id,
        );
        return findUId != '' ? findUId.name : '';
      } else {
        return '';
      }
    },
    getFilteredControlUnitName(index) {
      const MaterialStatusStockTemp = this.filteredMaterialControl[index]
        .material_id;
      const findMId = this.findInfoFromId(
        this.material,
        MaterialStatusStockTemp,
      );
      if (findMId !== '') {
        const findUId = this.findInfoFromId(
          this.unitCodes,
          findMId.incoming_unit_id,
        );
        return findUId != '' ? findUId.name : '';
      } else {
        return '';
      }
    },
    async openModal(index) {
      await this.selectRow(index);
      this.isGraphModalOpen = true;
    },
    closeModal() {
      this.isGraphModalOpen = false;
    },
    searchStore(e) {
      this.selectRow(-1);
      this.$store.commit('setSearchStoreLocationToMaterialStockStatus', e);
    },
    searchResource(e) {
      this.selectRow(-1);
      this.$store.commit('setSearchMaterialResourceToMaterialStockStatus', e);
    },
    exceptStockZero() {
      const selectListTemp = this.filterMaterialTotalStock[this.selectedIndex];

      this.$store.commit(
        'setCheckExceptStockZeroToMaterialStockStatus',
        !this.chkStockZero,
      );

      if (this.selectedIndex != -1) {
        const findIndex = this.lodash.clonedeep(
          this.filterMaterialTotalStock.findIndex(
            x => x.material_id == selectListTemp.material_id,
          ),
        );
        this.selectRow(findIndex);
      }
    },
    makeMaterialBarcode(material) {
      const completeList = this.lodash.clonedeep(material);
      console.log('completeList', completeList);
      completeList.input_date = completeList.create_time.substr(0, 10);

      if (completeList.input_date != undefined) {
        const date = completeList.input_date.replace(/-/gi, '');
        const fix5 = ('00000' + completeList.id).substr(-5);
        return 'M' + date + fix5;
      }
    },
    exportExcel() {
      let stockList = [];
      let searchMaterialName = '전체';

      // if (this.searchText == '') {
      //   searchMaterialName = '전체';
      // } else {
      //   let searchData = new Set(
      //     this.filteredMaterialStatus.map(x => x.material_name),
      //   );
      //   searchMaterialName = [...searchData].join(', ');
      // }

      const merge = [
        { s: { r: 0, c: 1 }, e: { r: 0, c: 6 } },
        { s: { r: 1, c: 1 }, e: { r: 1, c: 6 } },
        { s: { r: 3, c: 1 }, e: { r: 3, c: 6 } },
      ];
      let standardInfo = [
        ['기준시간', yyyy년MM월dd일HH시mm분ss초(new Date())],
        [],
        ['조회 원자재명', searchMaterialName],
        [],
        [],
        [],
      ];

      stockList = stockList.concat(standardInfo);

      stockList.push(['원자재명', '규격', '재고량(단위)']);

      this.filterMaterialTotalStock.forEach(el => {
        stockList.push([
          this.findInfoFromId(this.material, el.material_id).name,
          this.findInfoFromId(this.material, el.material_id).standard,
          this.$makeComma(
            this.findInfoFromId(this.materialTotalStock, el.material_id)
              .stock_quantity,
          ) +
            `(${
              this.findInfoFromId(
                this.unitCodes,
                this.findInfoFromId(this.material, el.material_id)
                  .incoming_unit_id,
              ).name
            })`,
        ]);
      });
      let wb = XLSX.utils.book_new();

      let stock = XLSX.utils.json_to_sheet(stockList, { skipHeader: true });

      stock['!merges'] = merge;

      XLSX.utils.book_append_sheet(wb, stock, '재고');

      let title = '원자재재고내역_(' + searchMaterialName + ').xls';

      XLSX.writeFile(wb, title);
    },
  },
  watch: {
    async managementMode(newValue) {
      if (!newValue) {
        await this.FETCH('FETCH_MATERIAL_CONTROL', '재고 관리');
        const controlListTemp = this.lodash.clonedeep(this.materialControlList);
        this.$store.commit('setMaterialControl', controlListTemp);
      }
    },
  },
  async created() {
    if (this.material.length < 1) {
      await this.FETCH('FETCH_MATERIAL_WITH_COMPANY', '원자재');
    }
    if (this.unitCodes.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
    if (this.store_options_all.length < 2) {
      await this.FETCH('FETCH_STORE', '창고');
    }
    if (this.materialInOutType.length < 1) {
      await this.FETCH('FETCH_MATERIAL_IN_OUT_TYPE', '자재 입출고 유형');
    }
    if (this.material_resource_options.length < 2) {
      await this.FETCH('FETCH_RESOURCE_TYPE', '자원 종류');
    }

    await this.FETCH('FETCH_MATERIAL_STATUS', '원자재 현황');
    await this.FETCH('FETCH_MATERIAL_TOTAL_STOCK', '재고 현황');
    await this.FETCH('FETCH_MATERIAL_CONTROL', '재고 관리');

    if (this.modal_start_date == null) {
      let date = new Date();
      let date2 = new Date();
      date2 = new Date(date2.setDate(date2.getDate() - 7));
      this.modal_start_date = yyyymmdd(date2);
      this.modal_end_date = yyyymmdd(date);
    }
  },
};
</script>

<style lang="scss" scoped></style>
