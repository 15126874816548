<template>
  <div class="modalPopup stock_modal" v-if="modalClose">
    <div class="modal_header">
      <h3 class="title">
        {{
          findInfoFromId(
            material,
            this.materialTotalStock[this.selectedIndex].material_id,
          ).name + ' - '
        }}
        입출고 현황
      </h3>
      <button type="button" class="modal_close" @click="isModalClose"></button>
    </div>
    <div class="modal_body">
      <div class="dateset">
        <div class="input_text">
          <input
            type="date"
            placeholder="YYYY-MM-DD"
            :value="startDate"
            @change="setStartDate"
          />~
        </div>
        <div class="input_text">
          <input
            type="date"
            placeholder="YYYY-MM-DD"
            :value="endDate"
            @change="setEndDate"
          />
        </div>
        <button class="excel_btn" @click="exportExcel">엑셀로 출력</button>
      </div>
      <div class="chart">
        <canvas id="chartData" height="150"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js';
import XLSX from 'xlsx';
import { mapGetters } from 'vuex';
import fetchMixin from '@/mixins/fetch';
import DRAG_MODAL_MIXIN from '@/mixins/drag_modal';
import { yyyy년MM월dd일HH시mm분ss초 } from '@/utils/func';

export default {
  mixins: [fetchMixin, DRAG_MODAL_MIXIN],
  props: ['filteredStockStatus', 'materialTotalStock', 'startDate', 'endDate'],
  data() {
    return {
      modalClose: true,
      screenWidth: this.$screen.width,
    };
  },
  computed: {
    ...mapGetters({
      stockStatusList: 'getMaterialStockStatusListFromMaterialStockStatus',
      selectedIndex: 'getSelectedIndexFromStock',
      material: 'getMaterial',
      materialInOutType: 'getMaterialInOutType',
      unitCodes: 'getUnitCodes',
      unit_conversions: 'getUnitConversion',
    }),
    filterExcelData() {
      return this.stockStatusList
        .filter(
          x =>
            x.material_id ==
              this.materialTotalStock[this.selectedIndex].material_id &&
            x.input_date >= this.startDate,
        )
        .sort(
          (b, a) =>
            Number(a.input_date.replace(/-/gi, '')) -
            Number(b.input_date.replace(/-/gi, '')),
        );
    },
  },
  methods: {
    isModalClose() {
      this.$emit('onclose');
    },
    setStartDate(e) {
      if (e.target.value != '') {
        if (e.target.value > this.endDate) {
          this.openOneButtonModal(
            '조회기간 오류',
            `조회기간 최후날짜(${this.endDate}) 이후의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.startDate;
        } else {
          this.$emit('changedate', {
            start_date: e.target.value,
            end_date: this.endDate,
          });
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        e.target.value = this.startDate;
      }
    },
    setEndDate(e) {
      if (e.target.value != '') {
        if (e.target.value < this.startDate) {
          this.openOneButtonModal(
            '조회기간 오류',
            `조회기간 최초날짜(${this.startDate}) 이전의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.endDate;
        } else {
          this.$emit('changedate', {
            start_date: this.startDate,
            end_date: e.target.value,
          });
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        e.target.value = this.endDate;
      }
    },
    exportExcel() {
      let inOutList = []; // 입출고
      let inList = []; // 입고
      let outList = []; // 출고
      let returnOutList = []; // 반품출고
      let discardList = []; // 폐기

      const date = this.startDate + ' ~ ' + this.endDate;

      let dataLen = this.filteredStockStatus.length - 1;
      let materialName = this.findInfoFromId(
        this.material,
        this.materialTotalStock[this.selectedIndex].material_id,
      ).name;

      const inUnutId = this.findInfoFromId(
        this.material,
        this.filterExcelData[0].material_id,
      ).incoming_unit_id;
      const outUnutId = this.findInfoFromId(
        this.material,
        this.filterExcelData[0].material_id,
      ).using_unit_id;
      const inUnitName = this.findInfoFromId(this.unitCodes, inUnutId).name;
      const outUnitName = this.findInfoFromId(this.unitCodes, outUnutId).name;

      const merge = [
        { s: { r: 0, c: 1 }, e: { r: 0, c: 3 } },
        { s: { r: 1, c: 1 }, e: { r: 1, c: 3 } },
        { s: { r: 3, c: 1 }, e: { r: 3, c: 2 } },
        { s: { r: 4, c: 1 }, e: { r: 4, c: 3 } },
      ];
      let standardInfo = [
        ['기준시간', yyyy년MM월dd일HH시mm분ss초(new Date())],
        ['조회기간', date],
        [],
        [
          '원자재명',
          materialName,
          '',
          '규격',
          this.findInfoFromId(
            this.material,
            this.materialTotalStock[this.selectedIndex].material_id,
          ).standard,
        ],
        [
          '총 재고량',
          this.$makeComma(this.filteredStockStatus[dataLen].currentStock) +
            ' (' +
            inUnitName +
            ')',
        ],
        [],
        [],
        [],
      ];

      inOutList = inOutList.concat(standardInfo); // 입출고는 입고단위로 맞추고
      inList = inList.concat(standardInfo);
      outList = outList.concat(standardInfo); // 출고는 단위환산해서 출고단위로 맞추기
      returnOutList = returnOutList.concat(standardInfo);
      discardList = discardList.concat(standardInfo);

      inOutList.push([
        '',
        '입/출고',
        '구매처',
        '입출고량(단위)',
        '총 재고량(단위)',
      ]);
      inList.push(['', '구매처', '입고량(단위)']);
      outList.push(['', '출고량(단위)']);
      returnOutList.push(['', '반품출고량(단위)']);
      discardList.push(['', '폐기량(단위)']);

      this.filterExcelData.forEach(el => {
        if (
          el.material_in_out_type_id ==
          this.materialInOutType.find(x => x.detail == 'buy in').id
        ) {
          inList.push([
            el.input_date,
            el.company_name,
            this.$makeComma(el.quantity) + ' (' + inUnitName + ')',
          ]);
          for (let i = 1; i < this.filteredStockStatus.length; i++) {
            let chk = this.filteredStockStatus[i];
            if (chk.date == el.input_date) {
              inOutList.push([
                el.input_date,
                '입고',
                el.company_name,
                this.$makeComma(el.quantity) + ' (' + inUnitName + ')',
                this.$makeComma(chk.currentStock) + ' (' + inUnitName + ')',
              ]);
            }
          }
        } else if (
          el.material_in_out_type_id ==
            this.materialInOutType.find(x => x.detail == 'using out').id ||
          el.material_in_out_type_id ==
            this.materialInOutType.find(x => x.detail == 'return out').id ||
          el.material_in_out_type_id ==
            this.materialInOutType.find(x => x.detail == 'discard out').id
        ) {
          const outType = this.materialInOutType.find(
            x => x.id == el.material_in_out_type_id,
          ).detail;
          const outTypeList =
            outType == 'using out'
              ? outList
              : outType == 'return out'
              ? returnOutList
              : discardList;

          let outQuantity = null;
          if (inUnutId != outUnutId) {
            outQuantity = this.$decimalDiv(
              Number(el.quantity),
              Number(
                this.unit_conversions.find(
                  x => x.in_unit_id == inUnutId && x.out_unit_id == outUnutId,
                ).cvr_ratio,
              ),
            );
          } else outQuantity = el.quantity;

          outTypeList.push([
            el.input_date,
            this.$makeComma(outQuantity) + ' (' + outUnitName + ')',
          ]);
          for (let i = 1; i < this.filteredStockStatus.length; i++) {
            const chk = this.filteredStockStatus[i];
            if (chk.date == el.input_date) {
              inOutList.push([
                el.input_date,
                `${
                  outType == 'using out'
                    ? '출고'
                    : outType == 'return out'
                    ? '반품출고'
                    : '폐기'
                }`,
                '-',
                this.$makeComma(el.quantity) + ' (' + inUnitName + ')',
                this.$makeComma(chk.currentStock) + ' (' + inUnitName + ')',
              ]);
            }
          }
        }
      });

      let wb = XLSX.utils.book_new();

      let dataInOut = XLSX.utils.json_to_sheet(inOutList, { skipHeader: true });
      let dataIn = XLSX.utils.json_to_sheet(inList, { skipHeader: true });
      let dataOut = XLSX.utils.json_to_sheet(outList, { skipHeader: true });
      let dataReturnOut = XLSX.utils.json_to_sheet(returnOutList, {
        skipHeader: true,
      });
      let dataDiscard = XLSX.utils.json_to_sheet(discardList, {
        skipHeader: true,
      });

      dataInOut['!merges'] = dataIn['!merges'] = dataOut[
        '!merges'
      ] = dataReturnOut['!merges'] = dataDiscard['!merges'] = merge;

      XLSX.utils.book_append_sheet(wb, dataInOut, '입출고');
      XLSX.utils.book_append_sheet(wb, dataIn, '입고');
      XLSX.utils.book_append_sheet(wb, dataOut, '출고');
      XLSX.utils.book_append_sheet(wb, dataReturnOut, '반품출고');
      XLSX.utils.book_append_sheet(wb, dataDiscard, '폐기');

      let title = materialName + ' 재고/입출고내역(' + date + ').xls';

      XLSX.writeFile(wb, title);
    },
  },
  mounted() {
    let labels = this.filteredStockStatus.map(x => x.date_label.substr(2, 9));

    let data = this.filteredStockStatus.map(x => x.currentStock);

    let label = this.filteredStockStatus.map(x => x.company_label);
    let type = this.filteredStockStatus.length <= 1 ? 'bar' : 'line';

    console.log('data', data);
    console.log('label', label);

    let data2 = {
      labels,
      datasets: [
        {
          label,
          borderColor: '#004a89',
          data,
          fill: false,
          pointRadius: 4,
          pointBackgroundColor: '#004a89',
          lineTension: 0,
          categoryPercentage: 0.4,
          barPercentage: 0.9,
        },
      ],
    };

    let fontSize = 12;
    if (this.screenWidth < 1280) {
      fontSize = 10;
    }
    let options = {
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            ticks: {
              fontSize,
            },
          },
        ],
        yAxes: [
          {
            stacked: false,
            ticks: {
              beginAtZero: true,
              fontSize,
              callback: value => {
                return this.$makeComma(value);
              },
            },
          },
        ],
      },
      plugins: {
        datalabels: {
          color: '#ffffff',
        },
      },
      elements: {
        point: {
          pointStyle: 'circle',
          pointColor: '#004a89',
          pointBackgroundColor: '#004a89',
        },
      },
      responsive: true,
      tooltips: {
        mode: 'index',
        intersect: false,
        custom: function(tooltip) {
          if (!tooltip) return;
          tooltip.displayColors = false;
        },
        callbacks: {
          label: tooltipItems => {
            let conv_y_label = this.$makeComma(
              this.$makeNumber(tooltipItems.yLabel),
            );
            if (conv_y_label.includes('.')) {
              conv_y_label =
                conv_y_label.split('.')[0] +
                '.' +
                conv_y_label.split('.')[1].replace(/,/gi, '');
            }

            var multistringText = [`[재고량] ${conv_y_label}`];
            label.forEach(element => {
              if (element != undefined) {
                element.forEach(el2 => {
                  if (el2.includes(tooltipItems.xLabel)) {
                    multistringText.push(el2);
                  }
                });
              }
            });
            return multistringText;
          },
        },
      },
      hover: {
        mode: 'nearest',
        intersect: true,
      },
    };
    let ctx = document.getElementById('chartData').getContext('2d');
    new Chart(ctx, {
      type,
      data: data2,
      options,
    });
  },
  created() {
    this.FETCH('FETCH_MATERIAL_STATUS', '원자재 현황');
    this.FETCH('FETCH_MATERIAL_WITH_COMPANY', '원자재');
    if (this.unit_conversions.length < 1) {
      this.FETCH('FETCH_UNIT_CONVERSION', '단위환산식');
    }
    if (this.materialInOutType.length < 1) {
      this.FETCH('FETCH_MATERIAL_IN_OUT_TYPE', '자재 입출고 유형');
    }
  },
};
</script>

<style lang="scss" scoped></style>
