<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <div class="tbl_opt">
          <span>입출고일자</span>
          <div>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                :value="startDate"
                @change="setStartDate($event)"
              />
            </div>
            <p>~</p>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                :value="endDate"
                @change="setEndDate($event)"
              />
            </div>
            <div class="input_search">
              <input
                type="text"
                placeholder="거래처명 검색"
                :value="companyText"
                @input="setCompanyText($event)"
              />
              <button>
                <i class="fa fa-search"></i>
              </button>
            </div>
            <div class="input_search">
              <input
                type="text"
                placeholder="원자재명 또는 규격 검색"
                :value="searchText"
                @input="setSearchText($event)"
              />
              <button>
                <i class="fa fa-search"></i>
              </button>
            </div>

            <button class="excel_btn" @click="exportExcel">엑셀로 출력</button>
          </div>
        </div>
      </div>
      <h6>조회수 : {{ filteredMaterialStatus.length }}건</h6>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <colgroup>
            <col v-for="(n, index) in 8" :key="index" />
          </colgroup>
          <thead>
            <tr>
              <th>구분</th>
              <th>입출고일자</th>
              <th>거래처명</th>
              <th>원자재명</th>
              <th>규격</th>
              <th>입고식별표</th>
              <th>수량(단위)</th>
              <th>투입공정</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="materialStatus in filteredMaterialStatus"
              :key="materialStatus.id"
              :class="
                target != null &&
                target.ident_value == 'M' + materialStatus.barcode_num
                  ? 'active'
                  : ''
              "
            >
              <td>
                {{
                  findInfoFromId(
                    materialInOutType,
                    materialStatus.material_in_out_type_id,
                  ).name
                }}
              </td>
              <td>
                {{ materialStatus.create_time | formatDateNoHours }}
              </td>
              <td class="text_left">{{ materialStatus.company_name }}</td>
              <td class="text_left">{{ materialStatus.material_name }}</td>
              <td class="text_left">{{ materialStatus.material_standard }}</td>
              <td>{{ 'M' + materialStatus.barcode_num }}</td>
              <td class="text_right">
                <b>{{ `${$makeComma(materialStatus.quantity)}` }}</b>
                {{
                  `(${
                    findInfoFromId(unitCodes, materialStatus.incoming_unit_id)
                      .name
                  })`
                }}
              </td>
              <td>
                {{
                  materialStatus.lot_number != null
                    ? `L${materialStatus.lot_number}`
                    : ''
                }}
                <button
                  v-show="materialStatus.lot_number != null"
                  class="btn_tbl"
                  @click="trackingLotNum(materialStatus.lot_number)"
                >
                  추적
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import XLSX from 'xlsx';
import { mapGetters } from 'vuex';
import { yyyymmdd } from '@/utils/func';
import fetchMixin from '@/mixins/fetch';
import modalMixin from '@/mixins/modal';
import { yyyy년MM월dd일HH시mm분ss초 } from '@/utils/func';
import routes from '@/routes/routes';

export default {
  mixins: [fetchMixin, modalMixin],
  data() {
    return {
      target: null,
    };
  },
  computed: {
    ...mapGetters({
      startDate: 'getStartDateFromMaterialStatus',
      endDate: 'getEndDateFromMaterialStatus',
      date: 'getDateFromMaterialStatus',
      searchText: 'getSearchTextFromMaterialStatus',
      companyText: 'getCompanyTextFromMaterialStatus',
      materialStatusList: 'getMaterialStatusListFromMaterialStatus',
      material: 'getMaterial',
      unitCodes: 'getUnitCodes',
      materialInOutType: 'getMaterialInOutType',
    }),
    filteredMaterialStatus() {
      if (this.materialStatusList !== undefined) {
        const Hangul = require('hangul-js');
        return this.materialStatusList
          .filter(
            x =>
              x.material_name.includes(this.searchText) ||
              Hangul.d(x.material_name, true)
                .map(x => x[0])
                .join('')
                .includes(this.searchText) ||
              x.material_standard.includes(this.searchText) ||
              Hangul.d(x.material_standard, true)
                .map(x => x[0])
                .join('')
                .includes(this.searchText),
          )
          .filter(
            x =>
              x.company_name.includes(this.companyText) ||
              Hangul.d(x.company_name, true)
                .map(x => x[0])
                .join('')
                .includes(this.companyText),
          )

          .sort(
            (a, b) =>
              new Date(b.create_time) - new Date(a.create_time) || b.id - a.id,
          );
      } else {
        return [];
      }
    },
  },
  methods: {
    setStartDate(e) {
      if (e.target.value != '') {
        if (e.target.value > this.endDate) {
          this.openOneButtonModal(
            '입고일자 오류',
            `입고일자 조회기간 최후날짜(${this.endDate})<br>이후의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.startDate;
        } else {
          this.$store.commit('setStartDateToMaterialStatus', e.target.value);
          // this.selectRow(-1);/
          this.FETCH_MATERIAL_STATUS_DAY();
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit(
          'setStartDateToMaterialStatus',
          yyyymmdd(new Date()),
        );
      }
    },
    setEndDate(e) {
      if (e.target.value != '') {
        if (e.target.value < this.startDate) {
          this.openOneButtonModal(
            '입고일자 오류',
            `입고일자 조회기간 최초날짜(${this.startDate})<br>이전의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.endDate;
        } else {
          this.$store.commit('setEndDateToMaterialStatus', e.target.value);
          // this.selectRow(-1);
          this.FETCH_MATERIAL_STATUS_DAY();
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setEndDateToMaterialStatus', yyyymmdd(new Date()));
      }
    },
    setSearchText(e) {
      this.$store.commit('setSearchTextToMaterialStatus', e.target.value);
    },
    setCompanyText(e) {
      this.$store.commit('setCompanyTextToMaterialStatus', e.target.value);
    },

    getUnitName(index) {
      const MaterialStatusTemp = this.materialStatusList[index].material_id;
      const findMId = this.findInfoFromId(this.material, MaterialStatusTemp);
      if (findMId !== '') {
        const findUId = this.findInfoFromId(
          this.unitCodes,
          findMId.incoming_unit_id,
        );
        return findUId != '' ? findUId.name : '';
      } else {
        return '';
      }
    },
    FETCH_MATERIAL_STATUS_DAY() {
      this.$store
        .dispatch('FETCH_MATERIAL_STATUS_DAY', {
          start: this.startDate,
          end: this.endDate,
        })
        .then(() => {})
        .catch(() => {
          this.openOneButtonModal(
            '로드 중 오류',
            '원자재입고현황 정보를 불러오는 중 오류가 발생했습니다.',
          );
        })
        .finally(() => {});
    },
    exportExcel() {
      let inOutList = [];
      let date = this.startDate + '~' + this.endDate;
      let searchMaterialName = '';

      if (this.searchText == '') {
        searchMaterialName = '전체';
      } else {
        let searchData = new Set(
          this.filteredMaterialStatus.map(x => x.material_name),
        );
        searchMaterialName = [...searchData].join(', ');
      }

      const merge = [
        { s: { r: 0, c: 1 }, e: { r: 0, c: 6 } },
        { s: { r: 1, c: 1 }, e: { r: 1, c: 6 } },
        { s: { r: 3, c: 1 }, e: { r: 3, c: 6 } },
      ];
      let standardInfo = [
        ['기준시간', yyyy년MM월dd일HH시mm분ss초(new Date())],
        ['조회기간', date],
        [],
        ['조회 원자재명', searchMaterialName],
        [],
        [],
        [],
      ];

      inOutList = inOutList.concat(standardInfo);

      inOutList.push([
        '',
        '구분',
        '거래처명',
        '원자재명',
        '규격',
        '입고식별표',
        '수량(단위)',
      ]);

      this.filteredMaterialStatus.forEach(el => {
        inOutList.push([
          el.input_date,
          this.findInfoFromId(
            this.materialInOutType,
            el.material_in_out_type_id,
          ).name,
          el.company_name,
          el.material_name,
          el.material_standard,
          'M' + el.barcode_num,
          this.$makeComma(el.quantity) +
            ' (' +
            this.findInfoFromId(this.unitCodes, el.incoming_unit_id).name +
            ')',
        ]);
      });
      let wb = XLSX.utils.book_new();

      let dataInOut = XLSX.utils.json_to_sheet(inOutList, { skipHeader: true });

      dataInOut['!merges'] = merge;

      XLSX.utils.book_append_sheet(wb, dataInOut, '입출고');

      let title =
        '원자재입출고내역(' + date + ')_(' + searchMaterialName + ').xls';

      XLSX.writeFile(wb, title);
    },
    trackingLotNum(lotNum) {
      const goingPage = routes[0].children.find(
        x => x.path == '/product/tracking',
      );
      if (goingPage != undefined) {
        goingPage.meta.select = Number(lotNum.substr(8, 5));
      }
      this.$router.push('/product/tracking');
    },
  },
  created() {
    if (this.$route.meta.target != undefined) {
      this.target = this.$route.meta.target;
      var date = new Date(this.target.create_time.substr(0, 10));
      this.$store.commit('setEndDateToMaterialStatus', yyyymmdd(date));
      var date2 = new Date(this.target.create_time.substr(0, 10));
      this.$store.commit('setStartDateToMaterialStatus', yyyymmdd(date2));
      delete this.$route.meta.target;
    } else if (this.startDate == null) {
      var date3 = new Date();
      this.$store.commit('setEndDateToMaterialStatus', yyyymmdd(date3));
      var date4 = new Date(date3.setDate(date3.getDate() - 7));
      this.$store.commit('setStartDateToMaterialStatus', yyyymmdd(date4));
    }
    this.FETCH('FETCH_MATERIAL_WITH_COMPANY', '원자재');
    this.FETCH('FETCH_UNIT', '단위');
    this.FETCH('FETCH_MATERIAL_IN_OUT_TYPE', '자재 입출고 유형');
    this.FETCH_MATERIAL_STATUS_DAY();
  },
};
</script>

<style lang="scss" scoped></style>
